import { useCallback, useState, useContext, useEffect } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';
import { useQueryClient } from '@tanstack/react-query';
import { ModalProvider } from '../../contexts/ModalContext';
import AppContext from '../../contexts/AppContext';
import { useUser } from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { putUserChangeDefaultSpace } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';

const NavigatePage = ({ testId, children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { spaceSlug } = useParams();
  const { appContext, updateAppContext } = useContext(AppContext);
  const [user] = useLocalStorageState('cms.user');
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const { t, i18n } = useTranslation();
  const [isLoaderPage, setIsLoaderPage] = useState(true);
  const [impersonate] = useLocalStorageState('cms.impersonate');

  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : 'pl');
  }, [i18n.language]);

  const { entity: userData, isLoading: userLoading } = useUser(user.data?.id);

  const handleUpdateSpaceContext = useCallback(
    (id, slug) => {
      updateAppContext?.((prevState) => ({
        ...prevState,
        space: id,
        spaceSlug: slug,
      }));
    },
    [updateAppContext],
  );

  const handleChangeSpace = useCallback(
    async (userID, newSpace) => {
      if (userID && newSpace?.id) {
        if (impersonate) {
          setIsLoaderPage(false);
          return;
        }

        setIsLoaderPage(true);
        try {
          const { body, status } = await putUserChangeDefaultSpace(jwt, space, {
            id: userID,
            spaceId: newSpace.id,
          });

          checkResponseStatus(body, status);
        } catch (error) {
          toast.error(error.message);
        }

        setIsLoaderPage(false);
      }
    },
    [impersonate, jwt, space],
  );

  const debouncedHandleChangeSpace = useDebounceCallback(
    handleChangeSpace,
    250,
  );

  const handleEntranceInSpace = useCallback(
    async (spaces, defaultSpace) => {
      if (spaceSlug) {
        const existSpace = spaces.filter((el) => el.slug === spaceSlug);
        const currentSpaceId = existSpace?.[0]?.id;
        const currentSpaceSlug = existSpace?.[0]?.slug;

        if (currentSpaceId && appContext?.space === currentSpaceId) {
          setIsLoaderPage(false);
          return;
        }

        setIsLoaderPage(true);

        handleUpdateSpaceContext(currentSpaceId, currentSpaceSlug);

        queryClient.removeQueries();

        if (!currentSpaceId) {
          toast.error(t('Spaces.ErrorWrongSpaceIdRedirectToDefault'));
          navigate('/');
        } else {
          debouncedHandleChangeSpace(user.data.id, existSpace[0]);

          TagManager.dataLayer({
            dataLayer: {
              event: 'space_changed',
              space_id: currentSpaceId,
              plan_id: existSpace[0]?.planLimits?.id,
              plan_name: existSpace[0]?.planLimits?.name,
            },
          });
        }
      } else if (defaultSpace?.slug) {
        navigate(`/s/${defaultSpace?.slug}`);
      } else {
        navigate(`/s/${space[0].slug}`);
      }
    },
    [
      spaceSlug,
      appContext?.space,
      handleUpdateSpaceContext,
      queryClient,
      t,
      navigate,
      debouncedHandleChangeSpace,
      user.data.id,
      space,
    ],
  );

  useEffect(() => {
    if (userLoading) return;
    if (!userData.spaces?.length) {
      setIsLoaderPage(false);
      navigate('/start');
      return;
    }

    const existDefaultSpace = !userData.defaultSpace?.slug
      ? null
      : userData.spaces.find((el) => el.slug === userData.defaultSpace.slug);

    handleEntranceInSpace(userData.spaces, existDefaultSpace);
  }, [handleEntranceInSpace, navigate, userData, userLoading]);

  const currentChildren = children ? children : <Outlet />;

  return (
    <ModalProvider>
      {isLoaderPage ? <LoaderPage testId={testId} /> : currentChildren}
    </ModalProvider>
  );
};

export default NavigatePage;
