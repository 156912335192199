import { useMemo, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';

// :: Component
import Heading from '../../components/Heading/Heading';
import RightSidebar from '../../components/RightSidebar/RightSidebar';
import AccountTypePill from '../../components/AccountTypePill/AccountTypePill';
import StatusBar from '../../components/StatusBar/StatusBar';
import DataGrid from '../../components/DataGrid/DataGrid';
import Loader from '../../components/Loader/Loader';
import StatusPill from '../../components/StatusPill/StatusPill';
import DateContainer from '../../components/DateContainer/DateContainer';
import Tooltip from '../../components/Tooltip/Tooltip';

// :: Component Inner
import HomeStructure from './HomeStructure';

// :: Utils
import { actionsRenderer } from '../../components/DataGrid/DataGridCell/cellRenderer';

// :: Lib
import {
  generateApiDocs,
  getObjectTitle,
  getTestProps,
  strToChecksum,
} from '../../lib/helpers';
import { markTaskAsDone } from '../../lib/flotiq-client/api-helpers';

// :: Sections
import AccountDetails from '../../sections/AccountDetails/AccountDetails';
import GetStarted from '../../sections/GetStarted/GetStarted';
import PackagesGrid from '../../sections/PackagesGrid/PackagesGrid';
import SocialsPanel from '../../sections/SocialsPanel/SocialsPanel';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import {
  useContentTypes,
  useSearch,
  useConstraints,
  useUser,
  useApiKeys,
} from '../../hooks/api';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import useToken from '../../hooks/useToken';
import useDarkMode from '../../hooks/useDarkMode';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Images
import {
  AngularLogo,
  CSharpLogo,
  DiscordColorIcon,
  PencilIcon,
  FacebookColorIcon,
  GithubColorIcon,
  GoLogo,
  JavaLogo,
  NodeJsLogo,
  PHPLogo,
  PostmanLogo,
  PythonLogo,
  RedditColorIcon,
  TwitterColorIcon,
  YoutubeColorIcon,
  LinkedinColorIcon,
  QuestionMarkCircleIcon,
  GithubColorIconWhite,
  RedditColorIconWhite,
} from '../../images/shapes';

const getProgressInformation = (count, limit, t, totalUnit = '') => {
  const progress = !limit && !count ? 1 : count / limit;
  return limit === -1
    ? {
        percent: 100,
        totalValue: t('Global.Unlimited'),
        hidePercentages: true,
        totalUnit: '',
      }
    : {
        percent: progress * 100,
        totalValue: limit,
        totalUnit: totalUnit,
      };
};

const LAST_CHANGES_LIMIT = 10;

const CTD_PARAMS = { limit: 10000 };
const APIKEY_PARAMS = {
  global: true,
  order_by: 'created_at',
  order_direction: 'asc',
};

const Home = ({ testId }) => {
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isAdmin, planLimits } = useContext(UserContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const [darkMode] = useDarkMode();

  const [userStorage, setUserStorage] = useLocalStorageState('cms.user');
  const userDetails = useMemo(() => userStorage?.data, [userStorage?.data]);
  const toDoList = useMemo(
    () => userDetails?.config?.todo,
    [userDetails?.config?.todo],
  );

  const { entity: ctoCount } = useConstraints('cto-count');
  const { entity: ctdCount } = useConstraints('ctd-count');
  const { entity: mediaCount } = useConstraints('media-sum-size');
  const { entity: apiKeysCount } = useConstraints('scoped-keys-count');

  const { entity: user } = useUser(userDetails?.id);
  const { data: apiKeysData } = useApiKeys(APIKEY_PARAMS);

  const [currentPage, setCurrentPage] = useState(1);

  const { data: contentTypes, isLoading: contentTypesLoading } =
    useContentTypes(CTD_PARAMS);

  const contentTypesDict = useMemo(
    () =>
      contentTypes.reduce((acc, ctd) => {
        acc[ctd.name] = ctd;
        return acc;
      }, {}),
    [contentTypes],
  );

  const searchParams = useMemo(
    () => ({
      q: '*',
      limit: LAST_CHANGES_LIMIT,
      order_by: 'internal.updatedAt',
      order_direction: 'desc',
      page: currentPage,
    }),
    [currentPage],
  );

  const {
    data: searchData,
    isLoading: searchLoading,
    pagination,
    reload,
  } = useSearch(searchParams);

  const jwt = useToken();

  const allContentItems = useMemo(() => {
    return searchData.map((el) => {
      return {
        cto: getObjectTitle(
          el.item,
          contentTypesDict[el.item.internal.contentType],
        ),
        ctd: {
          label:
            contentTypesDict[el.item.internal.contentType]?.label ||
            el.item.internal.contentType,
          name: el.item.internal.contentType,
          internal:
            !contentTypesDict[el.item.internal.contentType] ||
            contentTypesDict[el.item.internal.contentType].internal,
        },
        updated_at: el.item.internal.updatedAt,
        action: buildUrlWithSpace(
          el.item.internal.contentType === '_media'
            ? `media/edit/${el.item.id}`
            : `content-type-objects/edit/${el.item.internal.contentType}/${el.item.id}`,
        ),
      };
    });
  }, [contentTypesDict, searchData, buildUrlWithSpace]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'dashboard',
      topBar: {
        heading: t('Global.Dashboard'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_DOCUMENTATION,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
      breadcrumbs: [],
      id: 'dashboard',
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  const actions = useCallback(
    (link) => [
      {
        key: 'edit',
        label: t('Global.Edit'),
        icon: <PencilIcon className="text-blue w-3.5" />,
        link,
      },
    ],
    [t],
  );

  const accountResources = useMemo(
    () => [
      {
        label: t('Global.ContentTypeObjects'),
        value: ctoCount?.data,
        ...getProgressInformation(ctoCount?.data, planLimits?.cto_limit, t),
        key: 'ContentTypeObjects',
      },
      {
        label: t('Global.FileQuota'),
        value: mediaCount?.data,
        unit: 'MB',
        ...getProgressInformation(
          mediaCount?.data,
          planLimits?.file_quota,
          t,
          'MB',
        ),
        key: 'FileQuota',
      },
      {
        label: t('Global.ContentTypeDefinitions'),
        value: ctdCount?.data,
        ...getProgressInformation(ctdCount?.data, planLimits?.ctd_limit, t),
        key: 'ContentTypeDefinitions',
      },
      {
        label: t('Global.ScopedApiKeysCount'),
        value: apiKeysCount?.data,
        ...getProgressInformation(
          apiKeysCount?.data,
          planLimits?.scoped_keys_limit,
          t,
        ),
        key: 'ScopedApiKeysCount',
      },
    ],
    [planLimits, ctoCount, mediaCount, ctdCount, apiKeysCount, t],
  );

  const generateSDKUrl = useCallback(
    (lang) => {
      return process.env.REACT_APP_SDK_GENERATOR_URL
        ? `${process.env.REACT_APP_SDK_GENERATOR_URL}?lang=${lang}&token=${
            apiKeysData?.find(
              (key) => key.name.toLowerCase() === 'read and write api key',
            )?.apiKey
          }`
        : '';
    },
    [apiKeysData],
  );
  const generatePostmanUrl = useCallback(() => {
    return process.env.REACT_APP_POSTMAN_GENERATOR_URL
      ? `${process.env.REACT_APP_POSTMAN_GENERATOR_URL}?token=${
          apiKeysData?.find(
            (key) => key.name.toLowerCase() === 'read and write api key',
          )?.apiKey
        }&api=${
          process.env.REACT_APP_FLOTIQ_API_URL
        }/v1/internal/open-api-schema.json`
      : '';
  }, [apiKeysData]);

  const getCtdLink = useCallback(
    (ctd) => {
      if (ctd.internal && ctd.name !== '_media') return '';
      return ctd.name === '_media'
        ? buildUrlWithSpace('media')
        : buildUrlWithSpace(`content-type-objects/${ctd.name}`);
    },
    [buildUrlWithSpace],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'cto',
        label: t('Global.ObjectTitle'),
        minWidth: 140,
        render: (data) => (
          <span className="flex items-center h-full">
            <div className="truncate">{data}</div>
          </span>
        ),
        sortable: false,
      },
      {
        accessor: 'ctd',
        label: t('Global.ContentType'),
        minWidth: 110,
        render: (data) => (
          <StatusPill
            status={data.label}
            link={getCtdLink(data)}
            color={
              ['lime', 'blue', 'red', 'orange'][strToChecksum(data.name) % 4]
            }
            {...getTestProps(testId, `${data.name}-ctd`)}
            containerClasses={
              'flex items-center h-full text-xs lg:text-sm h-6 lg:h-7 truncate'
            }
            {...getTestProps(testId, `${data.name}-ctd`, 'testId')}
          />
        ),
        sortable: false,
      },
      {
        accessor: 'updated_at',
        label: t('Global.UpdatedAt'),
        minWidth: 100,
        render: (data) => <DateContainer date={data} isSemibold={false} />,
        sortable: false,
      },
      {
        accessor: 'action',
        label: '',
        minWidth: 50,
        width: 50,
        flexGrow: 0,
        notDraggable: true,
        render: (link, rowData) => (
          <div className={twMerge('flex items-center justify-start h-full')}>
            {rowData.ctd.internal && rowData.ctd.name !== '_media'
              ? ''
              : actionsRenderer(rowData, actions(link), false)}
          </div>
        ),
        sortable: false,
      },
    ],
    [actions, getCtdLink, t, testId],
  );

  return (
    <HomeStructure
      testId={testId}
      description={t('Global.WelcomeDashboard')}
      space={space}
      buildUrlWithSpace={buildUrlWithSpace}
      rightSidebar={
        <RightSidebar additionalClasses="h-fit md:pl-3.5 md:pr-7">
          {/* Account details */}
          <AccountDetails
            title={
              <div className="w-full flex flex-wrap justify-between gap-x-7 min-h-7">
                {t('Spaces.SpaceDetails')}
                <AccountTypePill
                  children={
                    user?.defaultSpace?.planLimits?.visible_name ||
                    user?.defaultSpace?.planLimits?.name
                  }
                  additionalClasses={twMerge(
                    'w-max',
                    user?.defaultSpace?.planLimits?.price !== 0
                      ? 'bg-gradient-blue'
                      : 'bg-gray-300',
                  )}
                  {...getTestProps(testId, 'account-type-pin', 'testId')}
                />
              </div>
            }
            resources={accountResources}
            {...(isAdmin && {
              seeMoreText: t('Global.SeeMore'),
              seeMoreLink: '/organization',
            })}
            id={'account_details'}
            {...getTestProps(testId, 'account-details', 'testId')}
          />

          {/* Get started*/}
          <GetStarted
            title={t('Global.FourStepsToGetStarted')}
            readMoreText={t('Global.CheckOurDocumentation')}
            readMoreLink={process.env.REACT_APP_DOCUMENTATION}
            tasks={[
              {
                name: t('Global.CreateYourProjectStructure'),
                description: t('Global.GoToDefinitionBuilder'),
                link: buildUrlWithSpace('content-type-definitions'),
                done: toDoList?.contentType?.status === 'done',
                key: 'Create',
              },
              {
                name: t('Global.AddContentObjectToYourProject'),
                link: toDoList?.contentType?.contentTypeSlug
                  ? buildUrlWithSpace(
                      `content-type-objects/${toDoList?.contentType?.contentTypeSlug}`,
                    )
                  : buildUrlWithSpace('content-type-definitions'),
                done: toDoList?.contentObject?.status === 'done',
                key: 'Add',
              },
              {
                name: t('Global.VisitAPIDocumentation'),
                link: generateApiDocs(
                  userDetails,
                  apiKeysData?.find(
                    (key) => key.name.toLowerCase() === 'read only api key',
                  ),
                ),
                done: toDoList?.publicDoc?.status === 'done',
                openInNewTab: true,
                onClick: () => {
                  markTaskAsDone('publicDoc', userStorage, jwt, setUserStorage);
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'apidocs_visit',
                    },
                  });
                },
                key: 'Visit',
              },
              {
                name: t('Global.TryOutStarterProjects'),
                link: buildUrlWithSpace('starters'),
                done: toDoList?.gatsbyStarters?.status === 'done',
                onClick: () =>
                  markTaskAsDone(
                    'gatsbyStarters',
                    userStorage,
                    jwt,
                    setUserStorage,
                  ),
                key: 'Try',
              },
            ]}
            id={'get_started'}
            {...getTestProps(testId, 'get-started', 'testId')}
          />

          {/* Api packages */}
          <PackagesGrid
            isOpen={false}
            isCollapsable={true}
            title={t('Global.YourAPIPackages')}
            helpPopup={
              <Tooltip
                tooltip={t('Global.YourAPIPackagesHelpText')}
                additionalTooltipClasses="md:max-w-52 2xl:max-w-xs"
              >
                <QuestionMarkCircleIcon
                  className={'h-4 w-4 text-slate-400/80'}
                />
              </Tooltip>
            }
            packages={[
              {
                icon: <AngularLogo className="w-3/4 h-3/4" title="Angular" />,
                downloadUrl: generateSDKUrl('typescript-angular'),
                fileName: 'flotiq-angular-sdk.zip',
              },
              {
                icon: <CSharpLogo className="w-3/4 h-3/4" title="C#" />,
                downloadUrl: generateSDKUrl('csharp'),
                fileName: 'flotiq-csharp-sdk.zip',
              },
              {
                icon: <GoLogo className="w-3/4 h-3/4" title="Go" />,
                downloadUrl: generateSDKUrl('go'),
                fileName: 'flotiq-goicon-sdk.zip',
              },
              {
                icon: <JavaLogo className="w-3/4 h-3/4" title="Java" />,
                downloadUrl: generateSDKUrl('java'),
                fileName: 'flotiq-java-sdk.zip',
              },
              {
                icon: <NodeJsLogo className="w-3/4 h-3/4" title="NodeJS" />,
                downloadUrl: generateSDKUrl('javascript'),
                fileName: 'flotiq-node-sdk.zip',
              },
              {
                icon: <PHPLogo className="w-3/4 h-3/4" title="PHP" />,
                downloadUrl: generateSDKUrl('php'),
                fileName: 'flotiq-php-sdk.zip',
              },
              {
                icon: <PostmanLogo className="w-3/4 h-3/4" title="Postman" />,
                downloadUrl: generatePostmanUrl(),
                fileName: 'flotiq-postman-collection.zip',
              },
              {
                icon: <PythonLogo className="w-3/4 h-3/4" title="Python" />,
                downloadUrl: generateSDKUrl('python'),
                fileName: 'flotiq-python-sdk.zip',
              },
            ]}
            additionalClasses="p-0"
            id="packages_grid"
            {...getTestProps(testId, `packages`, 'testId')}
          />

          {/* Socials Panel */}
          <SocialsPanel
            title={t('Global.SocialWidgetTitle')}
            items={[
              {
                name: 'Github',
                icon: darkMode ? (
                  <GithubColorIconWhite className="w-[24px] md:w-[26px]" />
                ) : (
                  <GithubColorIcon className="w-[24px] md:w-[26px]" />
                ),
                link: process.env.REACT_APP_GITHUB,
              },
              {
                name: 'Twitter',
                icon: <TwitterColorIcon className="w-[24px] md:w-[26px]" />,
                link: process.env.REACT_APP_TWITTER,
              },
              {
                name: 'Reddit',
                icon: darkMode ? (
                  <RedditColorIconWhite className="w-[24px] md:w-[26px]" />
                ) : (
                  <RedditColorIcon className="w-[24px] md:w-[26px]" />
                ),
                link: process.env.REACT_APP_REDDIT,
              },
              {
                name: 'LinkedIn',
                icon: <LinkedinColorIcon className="w-[24px] md:w-[26px]" />,
                link: process.env.REACT_APP_LINKEDIN,
              },
              {
                name: 'YouTube',
                icon: <YoutubeColorIcon className="w-[24px] md:w-[26px]" />,
                link: process.env.REACT_APP_YOUTUBE,
              },
              {
                name: 'Facebook',
                icon: <FacebookColorIcon className="!w-[24px] md:w-[26px]" />,
                link: process.env.REACT_APP_FACEBOOK,
              },
              {
                name: 'Discord',
                icon: <DiscordColorIcon className="w-[24px] md:w-[26px]" />,
                link: process.env.REACT_APP_DISCORD,
              },
            ]}
            id="socials_panel"
          />
        </RightSidebar>
      }
    >
      <>
        <Heading level={5} additionalClasses="mt-7 pb-1 dark:text-white">
          {t('Global.YourLatestUpdates')}
        </Heading>

        <div className="bg-white dark:bg-gray-900 rounded-lg mt-3">
          <div
            id="latest_updates"
            className="h-[calc(100%-40px)] md:h-[calc(100%-72px)]"
          >
            <DataGrid
              columns={columns}
              data={allContentItems}
              isLoading={contentTypesLoading || searchLoading}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={currentPage}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={reload}
                  resultsFrom={
                    (pagination.current_page - 1) * LAST_CHANGES_LIMIT + 1
                  }
                  resultsTo={
                    (pagination.current_page - 1) * LAST_CHANGES_LIMIT +
                    pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                />
              }
              noDataInfoText={t('ObjectsOfType.NoMatchingData')}
              loadingIcon={<Loader size={'small'} type={'spinner-grid'} />}
              {...getTestProps(testId, 'grid', 'testId')}
            />
          </div>
        </div>
      </>
    </HomeStructure>
  );
};

export default Home;

Home.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Home.defaultProps = {
  testId: '',
};
