import { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// :: contexts
import AppContext from '../../contexts/AppContext';

// :: hooks
import useOnce from '../../hooks/useOnce';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: subpages
import Profile from './subpages/Profile';

// :: images
import { HouseIcon } from '../../images/shapes';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

const AccountSettings = ({ testId }) => {
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { buildUrlWithSpace } = useSelectedSpace();

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'account-settings',
      id: 'accountSettings',
      topBar: {
        heading: t('Global.AccountSettings'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_DOCUMENTATION,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.AccountSettings'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'AccountSettings',
        },
      ],
    }));
  }, [t, buildUrlWithSpace, updateAppContext]);

  return (
    <div className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('Global.AccountSettings')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full relative mt-7"
          {...getTestProps(testId, 'container')}
        >
          <Profile owner={true} mode={'edit'} />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;

AccountSettings.propTypes = {
  /**
   * Selected tab index
   */
  testId: PropTypes.string,
};

AccountSettings.defaultProps = {
  testId: '',
};
