import {
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

// :: Hooks
import {
  useContentObject,
  useContentType,
  usePluginsSettings,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { useModals } from '../../contexts/ModalContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';

// :: Images
import { ClipboardIcon, HouseIcon, WarningIcon } from '../../images/shapes';

// :: Lib
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import { patchMedia } from '../../lib/flotiq-client/api-helpers';
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';

// :: Component
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectBacklinks from '../AddContentObject/ContentObjectBacklinks/ContentObjectBacklinks';
import MediaForm from '../../form/MediaForm/MediaForm';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import useFirstLoading from '../../hooks/useFirstLoading';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import { useBacklinks } from '../../hooks/api/useBacklinks';

const USER_PLUGINS_PARAMS = {
  limit: 1000,
  page: 1,
};

const EditMedia = ({ testId }) => {
  const jwt = useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateOnSave = useRef();
  const modal = useModals();
  const { updateAppContext } = useContext(AppContext);
  const { permissions } = useContext(UserContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { setDirty } = useContext(DirtyHandlerContext);

  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);
  const { entity: mediaContentType } = useContentType('_media');

  const {
    entity: media,
    isLoading,
    status,
    errors,
    deleteEntity: deleteMediaObject,
  } = useContentObject('_media', id);

  const firstLoading = useFirstLoading(!isLoading || !id, id);

  useApiErrorsToast(errors);

  const handleDeleteMedia = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteMediaObject({
        contentTypeName: '_media',
      });
      checkResponseStatus(body, status);

      toast.success(t('Media.MediaManagement.DeleteSuccess'));
      setDirty(false);
      navigate(buildUrlWithSpace('media'));
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message
            ? error.message
            : t('Media.MediaManagement.CouldntDelete'),
        );
      }
    }
  }, [modal, deleteMediaObject, t, setDirty, navigate, buildUrlWithSpace]);

  const deleteMedia = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(
      <div className="dark:text-white">
        <Heading additionalClasses="dark:text-white" level={6}>
          {t('MediaEdit.ConfirmDelete')}
        </Heading>
        <p>{t('Media.MediaManagement.DeleteWarning')}</p>
      </div>,
      'delete-modal',
      () => handleDeleteMedia(),
    );
    setIsDeleting(false);
  }, [handleDeleteMedia, modal, t]);

  const { canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCoPermissions('_media') || {},
    [permissions],
  );

  const topBarButtons = useMemo(() => {
    const buttons = [
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        link: buildUrlWithSpace(`media`),
        color: 'gray',
        disabled: isSaving || isDeleting,
      },
    ];

    if (canUpdate && id) {
      buttons.push({
        key: 'save',
        label: t('Global.Save'),
        onClick: () => {
          navigateOnSave.current = false;
        },
        iconImage: isSaving ? <Loader size="small" type="spinner-grid" /> : '',
        disabled: isLoading || isSaving || isDeleting,
        menuItems: [
          {
            key: 'leave',
            label: (
              <div className="whitespace-nowrap">
                {t('Global.SaveAndLeave')}
              </div>
            ),
            type: 'submit',
            form: 'media-form',
            onClick: () => {
              navigateOnSave.current = true;
            },
          },
        ],
        type: 'submit',
        form: 'media-form',
        ...getTestProps(testId, 'save', 'testId'),
      });
    }

    if (id && canDelete)
      buttons.unshift({
        key: 'delete',
        label: t('Global.Delete'),
        onClick: deleteMedia,
        color: 'redBordered',
        iconImage: isDeleting ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          ''
        ),
        disabled: isLoading || isSaving || isDeleting,
        ...getTestProps(testId, 'delete', 'testId'),
      });
    return buttons;
  }, [
    t,
    isSaving,
    isDeleting,
    canUpdate,
    id,
    canDelete,
    deleteMedia,
    isLoading,
    testId,
    buildUrlWithSpace,
  ]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'media',
      menuItemOpen: t('Global.MediaLibrary'),
      topBar: {
        heading: t('Global.MediaLibrary'),
        buttons: topBarButtons,
      },
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'dashboard',
        },
        {
          label: t('Global.MediaLibrary'),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'mediaLibrary',
        },
        {
          label: t('MediaEdit.Edit'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'editor',
        },
      ],
    }));
  }, [t, topBarButtons, updateAppContext, buildUrlWithSpace]);

  const {
    backlinks,
    backlinkContentTypes,
    backlinkPagination,
    setBacklinksPage,
  } = useBacklinks(id, '_media');

  const updateMedia = useCallback(
    async (values) => {
      setIsSaving(true);
      const result = await patchMedia(jwt, space, id, values, t);
      setIsSaving(false);
      return result;
    },
    [jwt, space, id, t],
  );

  const showForm = id && media && !firstLoading && canRead;

  const emptyData = useMemo(() => {
    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loader` : ''}
        />
      );
    }
    if (!canRead) {
      toast.error(t('MediaEdit.CouldntFind', { id }));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('MediaEdit.CouldntFind', { id })}
          />
          {status === 404 || !canRead
            ? t('MediaEdit.CouldntFind', { id })
            : t('MediaEdit.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [showForm, firstLoading, canRead, testId, t, id, status]);

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType: mediaContentType,
      contentObject: media,
      disabled: isSaving || isDeleting,
      duplicate: false,
      create: false,
      userPlugins,
    },
  );

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType: mediaContentType,
      initialData: media,
      userPlugins: userPlugins,
    }),
    [media, mediaContentType, userPlugins],
  );

  return (
    <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('MediaEdit.Edit')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        {showForm ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 h-full relative mt-7">
            <div className="space-y-3 md:space-y-6 mx-4 xl:ml-7 xl:mr-3.5 mb-7 col-span-2">
              <ContentObjectFormContext.Provider
                value={contentObjectFormContextValue}
              >
                <MediaForm
                  media={media}
                  contentType={mediaContentType}
                  onSubmit={updateMedia}
                  navigateOnSave={navigateOnSave}
                  disabled={isSaving || isDeleting || !canUpdate}
                  {...getTestProps(testId, 'form', 'testId')}
                />
              </ContentObjectFormContext.Provider>
            </div>
            <div
              className={twMerge(
                'px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800',
                'flex flex-col gap-5 w-full',
              )}
            >
              <ContentObjectInformations
                id={media.id}
                createdAt={media.internal?.createdAt}
                updatedAt={media.internal?.updatedAt}
                additionalElements={
                  <div
                    className={twMerge(
                      'flex items-start text-indigo-950 dark:text-gray-200',
                    )}
                  >
                    <ClipboardIcon className="text-blue min-w-[18px] w-[18px] ml-0.5 mr-1.5 xl:mr-3" />
                    <span>ID</span>
                    <span className="font-semibold mx-1 break-all">{id}</span>
                  </div>
                }
                additionalClasses="order-10"
                {...getTestProps(testId, 'info', 'testId')}
              />

              {backlinks?.length > 0 && (
                <ContentObjectBacklinks
                  backlinks={backlinks}
                  contentTypes={backlinkContentTypes}
                  pagination={backlinkPagination}
                  onPageChange={setBacklinksPage}
                  additionalClasses="order-20"
                  {...getTestProps(testId, 'backlinks', 'testId')}
                />
              )}

              {pluginSidebarPanels?.length > 0 && (
                <ElementFromPlugin results={pluginSidebarPanels} />
              )}
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center h-full
            bg-white dark:bg-slate-950 rounded-lg m-4 xl:m-12"
          >
            {emptyData}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditMedia;

EditMedia.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

EditMedia.defaultProps = {
  testId: '',
};
