import { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';

// :: React dnd
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

// :: Layout
import Structure from './Structure';

// :: Components
import GlobalSearch from '../components/GlobalSearch/GlobalSearch';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';

// :: Context
import AppContext from '../contexts/AppContext';
import NewMediaContext from '../contexts/NewMediaContext';
import SidebarContext from '../contexts/SidebarContext';
import UserContext from '../contexts/UserContext';

// :: Hooks
import {
  useContentTypes,
  usePlugins,
  useUser,
  useConstraints,
  usePluginsSettings,
  useApiKeys,
} from '../hooks/api';
import useOnce from '../hooks/useOnce';
import useApiErrorsToast from '../hooks/api/useApiErrorsToast';
import useDarkMode from '../hooks/useDarkMode';
import useSelectedSpace from '../hooks/useSelectedSpace';
import useToken from '../hooks/useToken';

// :: Lib
import { generateApiDocs, getTestProps } from '../lib/helpers';
import { checkResponseStatus } from '../lib/flotiq-client/response-errors';
import FlotiqPlugins from '../lib/flotiq-plugins/flotiqPluginsRegistry';
import { LanguageChangedEvent } from '../lib/flotiq-plugins/plugin-events/LanguageChangedEvent';
import { putUserConfig } from '../lib/flotiq-client';
import { markTaskAsDone } from '../lib/flotiq-client/api-helpers';

// :: Images
import {
  CopyColorIcon,
  KeyColorIcon,
  ClipboardColorIcon,
  FourSquaresColor,
  LogOutIcon,
  MediaColorIcon,
  PuzzleColorIcon,
  LightningColorIcon,
  UsersIcon,
  ConnectedDotsColorIcon,
  ConnectedDotsColorIconWhite,
  GearColorIcon,
  UnionIcon,
  ReturnOldFront,
  SunIcon,
  MoonIcon,
  FourSquaresColorWhite,
  MediaColorIconWhite,
  StarFavIcon,
  UsersKeyIcon,
  UsersKeyIconWhite,
  ClipboardColorIconWhite,
  GearColorIconWhite,
  PuzzleColorIconWhite,
  LightningColorIconWhite,
  KeyColorIconWhite,
  CopyColorIconWhite,
  ReturnOldFrontWhite,
  AvatarIcon,
  HouseIcon,
  FolderIconWhite,
  FolderIcon,
} from '../images/shapes';
import LoaderPage from '../pages/LoaderPage/LoaderPage';

const CTD_PARAMS = {
  internal: 0,
  limit: 10000,
  order_by: 'label',
  order_direction: 'asc',
  page: 1,
};

const PLUGINS_LEGACY_PARAMS = {
  limit: 10000,
  page: 1,
  filters: JSON.stringify({
    type: {
      type: 'equals',
      filter: 'ui-extension',
    },
  }),
};

const USER_PLUGINS_PARAMS = {
  limit: 10000,
  page: 1,
};

const APIKEY_PARAMS = { name: 'read only api key' };

/**
 * @emits FlotiqPlugins."flotiq.language::changed"
 */
const Layout = ({ testId, additionalClasses, children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useDarkMode();
  const { appContext, updateAppContext } = useContext(AppContext);
  const { isRoleAdmin, isAdmin, permissions, updateUserContext } =
    useContext(UserContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const [user, setUser] = useLocalStorageState('cms.user');
  const { data: apiKeysData } = useApiKeys(APIKEY_PARAMS);
  const [layoutLoading, setLayoutLoading] = useState(FlotiqPlugins.enabled());
  const [pluginsLoaded, setPluginLoaded] = useState(false);

  const { entity: mimeTypes } = useConstraints('mimetypes');

  const updateUserMimeTypes = useCallback(async () => {
    if (mimeTypes?.data && user) {
      setUser({
        ...user,
        data: { ...user.data, allowedMimetypes: mimeTypes.data },
      });
    }
  }, [mimeTypes?.data, setUser, user]);

  useOnce(updateUserMimeTypes);

  const jwt = useToken();

  const systemPlugins = useMemo(
    () => JSON.parse(process.env.REACT_APP_SYSTEM_PLUGINS || '[]'),
    [],
  );

  const { data: pluginsLegacy, isLoading: pluginsLegacyAreLoading } =
    usePlugins(PLUGINS_LEGACY_PARAMS);

  const { data: userPlugins, isLoading: userPluginsAreLoading } =
    usePluginsSettings(USER_PLUGINS_PARAMS);

  useEffect(() => {
    if (
      !user ||
      (permissions.userRoles &&
        !pluginsLegacyAreLoading &&
        !pluginsLegacy.length &&
        !userPluginsAreLoading &&
        !userPlugins.length)
    ) {
      setLayoutLoading(false);
    }
  }, [
    permissions.userRoles,
    pluginsLegacy.length,
    pluginsLegacyAreLoading,
    user,
    userPlugins.length,
    userPluginsAreLoading,
  ]);

  useEffect(() => {
    if (
      !permissions.userRoles ||
      userPluginsAreLoading ||
      pluginsLegacyAreLoading
    )
      return;

    if (pluginsLoaded) return;

    const enabledPlugins = userPlugins?.filter(({ enabled }) => enabled);

    const pluginsLegacyUI = pluginsLegacy.map((plugin) => {
      const url = plugin.settings?.find(
        (setting) => setting.key === 'source-url',
      )?.value;
      return { id: plugin?.id, url, settings: '' };
    });

    Promise.all(
      [...pluginsLegacyUI, ...systemPlugins, ...enabledPlugins].map(
        ({ id, url, settings }) => {
          if (!id || !url) return null;
          return FlotiqPlugins.loadPlugin(id, url, settings);
        },
      ),
    ).then(() => {
      setLayoutLoading(false);
      setPluginLoaded(true);
    });
  }, [
    permissions.userRoles,
    pluginsLegacy,
    pluginsLegacyAreLoading,
    pluginsLoaded,
    systemPlugins,
    userPlugins,
    userPluginsAreLoading,
  ]);

  const { onUpload } = useContext(NewMediaContext);

  const oldDashboardUrl = useMemo(
    () => process.env.REACT_APP_OLD_FRONTEND_URL,
    [],
  );

  const { entity: userData } = useUser(user?.data?.id);

  const {
    data: contentTypes,
    errors: ctdErrors,
    reload: reloadCtd,
  } = useContentTypes(CTD_PARAMS);

  const planLimits = useMemo(
    () => userData?.spaces?.filter((el) => el.id === space)?.[0]?.planLimits,
    [space, userData?.spaces],
  );

  const handleUpdatePlan = useCallback(() => {
    updateUserContext?.((prevState) => ({
      ...prevState,
      planLimits,
    }));
  }, [updateUserContext, planLimits]);

  useOnce(handleUpdatePlan);

  const menuSpaceContentItems = useMemo(() => {
    if (!userData) return null;

    const spacesMenuOptions = [];

    if (userData?.spaces?.length) {
      const userSpaces = userData?.spaces.map((userSpace) => {
        return {
          ...userSpace,
          key: userSpace.id,
          title: userSpace.name,
          onClick: () => {
            navigate(`/s/${userSpace.slug}`);
            if (userSpace.id !== space) FlotiqPlugins.unregisterAllPlugins();
          },
        };
      });
      spacesMenuOptions.push(...userSpaces);
    }

    if (isAdmin) {
      spacesMenuOptions.push({
        key: 'manage-spaces',
        title: t('Spaces.ManageSpaces'),
        value: 'manage-spaces',
        link: '/organization',
        additionalClasses: 'text-blue-600 underline',
      });
      spacesMenuOptions.push({
        key: 'add-space',
        title: t('Spaces.PlusAddSpace'),
        value: 'manage-spaces',
        link: '/space/add',
        additionalClasses: 'text-blue-600 underline',
      });
    }

    return spacesMenuOptions;
  }, [userData, isAdmin, navigate, space, t]);

  const pinContentCallback = useCallback(
    async (id, pined) => {
      if (!user?.data) return;

      const removeElement = (arr, id) => {
        const index = arr[space].indexOf(id);
        if (index > -1) {
          arr[space].splice(index, 1);
        }
        return arr;
      };

      let newPinnedContent = { ...user.data.config?.pinedDefinitions };

      if (!pined) {
        if (!newPinnedContent[space]) {
          newPinnedContent[space] = [id];
        } else {
          newPinnedContent[space].push(id);
        }
      } else {
        newPinnedContent = removeElement(newPinnedContent, id);
      }

      const newConfig = {
        ...user.data.config,
        pinedDefinitions: newPinnedContent,
      };

      try {
        const { body, status } = await putUserConfig(jwt, null, {
          id: user.data.id,
          config: newConfig,
        });
        checkResponseStatus(body, status);

        const newUser = { ...user };
        newUser.data.config = newConfig;

        setUser(newUser);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
      }
    },
    [jwt, setUser, space, t, user],
  );

  const menuContentItems = useMemo(() => {
    if (!contentTypes) return [[], []];

    const pinedElements = [];

    const dict = contentTypes.reduce((folders, el) => {
      if (!permissions?.canCo(el.name)) return folders;
      const hasFolder = el.label.includes('/');
      const [splitFolderName, ...subfolderNameParts] =
        el.label?.split('/') || [];
      const folderName = hasFolder ? splitFolderName : '';
      const splitLabel = hasFolder ? subfolderNameParts.join('/') : el.label;

      const pinedContent = user?.data?.config?.pinedDefinitions;
      const isPinned = pinedContent?.[space]?.includes(el.id);

      const menuElement = {
        key: splitLabel,
        name: el.name,
        id: el.id,
        title: splitLabel || el.label,
        link: buildUrlWithSpace(`content-type-objects/${el.name}`),
        pined: isPinned,
      };

      if (isPinned) {
        pinedElements.push({
          ...menuElement,
          title: el.label,
          link: buildUrlWithSpace(
            `content-type-objects/${el.name}?favorites=1`,
          ),
          id: el.id,
          key: `${el.label}-pinned`,
        });
      }

      if (folders[folderName]) folders[folderName].push(menuElement);
      else folders[folderName] = [menuElement];
      return folders;
    }, {});
    const items = [];

    Object.keys(dict)
      .sort((a, b) => {
        if (!a) return 1;
        if (!b) return -1;

        return a > b ? 1 : -1;
      })
      .forEach((key) => {
        if (key === '') items.push(...dict[key]);
        else {
          items.push({
            key: key,
            name: key,
            title: key,
            children: dict[key],
          });
        }
      });

    return [items || [], pinedElements || []];
  }, [
    contentTypes,
    permissions,
    user?.data?.config?.pinedDefinitions,
    space,
    buildUrlWithSpace,
  ]);

  useApiErrorsToast(ctdErrors);

  const handleGetUserDetails = useCallback(() => {
    const userDetails = user?.data;
    const token = user?.token;

    if (
      !appContext?.user?.username ||
      appContext?.user?.username !== user?.data?.username
    ) {
      updateAppContext?.((prevState) => ({
        ...prevState,
        ...(token && { token }),
        ...(userDetails && { user: userDetails }),
      }));
    }
  }, [user?.data, user?.token, appContext?.user?.username, updateAppContext]);

  useOnce(handleGetUserDetails);

  const SIDEBAR_MENU_ITEMS = useMemo(() => {
    let currentItems = [];

    const [menuItems, pinedItems] = menuContentItems;

    if (space) {
      currentItems.push({
        key: 'spaces',
        icon: darkMode ? (
          <FolderIconWhite className="w-6 min-w-6" />
        ) : (
          <FolderIcon className="w-6 min-w-6" />
        ),
        title:
          menuSpaceContentItems?.filter((el) => el.id === space)?.[0]?.title ||
          '',
        hidePin: true,
        children: menuSpaceContentItems,
        titleClassName: 'font-bold line-clamp-1',
        className: `h-10 ${
          !menuSpaceContentItems?.length && 'pointer-events-none'
        }`,
        popover: true,
      });

      currentItems.push(
        ...[
          {
            key: 'dashboard',
            icon: darkMode ? (
              <FourSquaresColorWhite className="w-6 min-w-6" />
            ) : (
              <FourSquaresColor className="w-6 min-w-6" />
            ),
            title: t('Global.Dashboard'),
            link: buildUrlWithSpace(''),
          },
          {
            key: 'favorites',
            icon: <StarFavIcon className="w-6 min-w-6" />,
            title: t('Global.Favorite'),
            children: pinedItems,
            className: !permissions?.cosVisible() ? 'hidden' : '',
          },
          {
            key: 'media',
            icon: darkMode ? (
              <MediaColorIconWhite className="w-6 min-w-6" />
            ) : (
              <MediaColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.MediaLibrary'),
            link: buildUrlWithSpace('media'),
            className: !permissions?.canCo('_media') ? 'hidden' : '',
          },
          {
            key: 'content',
            icon: darkMode ? (
              <ClipboardColorIconWhite className="w-6 min-w-6" />
            ) : (
              <ClipboardColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.Content'),
            ...(menuItems.length
              ? { children: menuItems }
              : { link: buildUrlWithSpace('content') }),
            className: !permissions?.cosVisible() ? 'hidden' : '',
          },
          {
            key: 'definitionBuilder',
            icon: darkMode ? (
              <GearColorIconWhite className="w-6 min-w-6" />
            ) : (
              <GearColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.DefinitionBuilder'),
            link: buildUrlWithSpace('content-type-definitions'),
            className: !permissions?.ctdsVisible() ? 'hidden' : '',
          },
          {
            key: 'webhooks',
            icon: darkMode ? (
              <ConnectedDotsColorIconWhite className="w-6 min-w-6" />
            ) : (
              <ConnectedDotsColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.Webhooks'),
            link: buildUrlWithSpace('webhooks'),
            className: !permissions?.canCo('_webhooks') ? 'hidden' : '',
          },
          {
            key: 'plugins',
            icon: darkMode ? (
              <PuzzleColorIconWhite className="w-6 min-w-6" />
            ) : (
              <PuzzleColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.Plugins'),
            link: buildUrlWithSpace('plugins'),
            className: !permissions?.canCo('_plugin_settings') ? 'hidden' : '',
          },
          {
            key: 'starters',
            icon: darkMode ? (
              <LightningColorIconWhite className="w-6 min-w-6" />
            ) : (
              <LightningColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.Starters'),
            link: buildUrlWithSpace('starters'),
          },
          {
            key: 'apiKeys',
            icon: darkMode ? (
              <KeyColorIconWhite className="w-6 min-w-6" />
            ) : (
              <KeyColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.APIKeys'),
            link: buildUrlWithSpace('api-keys'),
          },
          {
            key: 'apiDocs',
            icon: darkMode ? (
              <CopyColorIconWhite className="w-6 min-w-6" />
            ) : (
              <CopyColorIcon className="w-6 min-w-6" />
            ),
            title: t('Global.APIDocs'),
            link: generateApiDocs(user?.data, apiKeysData?.[0]),
            target: '_blank',
            rel: 'noreferrer',
            onClick: () => {
              markTaskAsDone('publicDoc', user, jwt, setUser);
              TagManager.dataLayer({
                dataLayer: {
                  event: 'apidocs_visit',
                },
              });
            },
          },
        ],
      );

      if (
        isAdmin &&
        (!!planLimits?.user_roles_enabled ||
          !!planLimits?.predefined_user_roles) &&
        process.env.REACT_APP_ENABLE_USER_ROLES.split(',').join(',') === 'true'
      ) {
        currentItems.splice(5, 0, {
          key: 'user-roles',
          icon: darkMode ? (
            <UsersKeyIconWhite className="w-6 min-w-6" />
          ) : (
            <UsersKeyIcon className="w-6 min-w-6" />
          ),
          title: t('Global.UserRoles'),
          link: buildUrlWithSpace('user-roles'),
        });
      }
    }

    if (isRoleAdmin) {
      currentItems.push({
        key: 'admin',
        className: 'border-t border-gray dark:border-slate-800 mt-1.5',
        icon: <StarFavIcon className="w-6 min-w-6" />,
        title: 'Admin',
        hidePin: true,
        children: [
          {
            key: 'all-users',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.AllUsers'),
            link: '/users-data-preview',
          },
          {
            key: 'all-spaces',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.AllSpaces'),
            link: '/spaces-data-preview',
          },
          {
            key: 'plans',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.Plans'),
            link: '/plans',
          },
        ],
      });
    }

    if (oldDashboardUrl) {
      currentItems.push({
        key: 'oldFront',
        className:
          'border-t border-gray dark:border-slate-800 text-[15px] min-h-[50px]',
        titleClassName: 'leading-4 line-clamp-2',
        icon: darkMode ? (
          <ReturnOldFrontWhite className="w-6 min-w-6" />
        ) : (
          <ReturnOldFront className="w-6 min-w-6" />
        ),
        title: t('Global.ReturnToOldFrontMenuLink'),
        link: oldDashboardUrl,
      });
    }

    if (!space) {
      currentItems.unshift({
        key: 'dashboard',
        icon: darkMode ? (
          <FourSquaresColorWhite className="w-6 min-w-6" />
        ) : (
          <FourSquaresColor className="w-6 min-w-6" />
        ),
        title: t('Global.Dashboard'),
        link: `/`,
      });
    }

    return currentItems;
  }, [
    menuContentItems,
    space,
    isRoleAdmin,
    oldDashboardUrl,
    darkMode,
    menuSpaceContentItems,
    t,
    buildUrlWithSpace,
    permissions,
    user,
    apiKeysData,
    isAdmin,
    planLimits?.user_roles_enabled,
    planLimits?.predefined_user_roles,
    jwt,
    setUser,
  ]);

  useEffect(() => {
    const lngChangedCallback = (language) => {
      FlotiqPlugins.run(
        'flotiq.language::changed',
        new LanguageChangedEvent({ language }),
      );
      moment.locale(language === 'en' ? 'en-gb' : 'pl');
    };

    i18n.on('languageChanged', lngChangedCallback);
    return () => i18n.off('languageChanged', lngChangedCallback);
  }, [i18n]);

  const MENU_USER_ITEMS = useMemo(() => {
    const currentMenu = [
      {
        icon: darkMode ? (
          <SunIcon className="w-4 text-white" />
        ) : (
          <MoonIcon className="w-4 text-indigo-950" />
        ),
        text: darkMode
          ? t('Global.ChooseLightTheme')
          : t('Global.ChooseDarkTheme'),
        onClick: () => setDarkMode(!darkMode),
      },
      {
        icon: (
          <AvatarIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
        ),
        text: t('Global.ProfileSettings'),
        link: '/profile',
      },
      {
        icon: (
          <LogOutIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
        ),
        text: t('Global.LogOut'),
        link: '/logout',
      },
    ];

    if (isAdmin) {
      currentMenu.splice(1, 0, {
        icon: <HouseIcon className="w-4 h-4 text-indigo-950 dark:text-white" />,
        text: t('Global.OrganizationSettings'),
        link: '/organization',
        hidden: true,
      });
    }

    return currentMenu;
  }, [darkMode, setDarkMode, isAdmin, t]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        onUpload(item.files);
      },
      canDrop: () => true,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onUpload],
  );

  const sidebarContextValue = useMemo(() => ({ reloadCtd }), [reloadCtd]);

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  return layoutLoading ? (
    <LoaderPage />
  ) : (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <GlobalSearch />
      <SidebarContext.Provider value={sidebarContextValue}>
        <Structure
          testId={testId}
          additionalClasses={additionalClasses}
          ref={() => drop(document.body)}
          logoLink={space ? buildUrlWithSpace('') : '/'}
          menuUserItems={MENU_USER_ITEMS}
          sidebarMenuItems={SIDEBAR_MENU_ITEMS}
          pinContentCallback={pinContentCallback}
          additionalElement={
            <Transition
              className="z-50"
              show={canDrop && isOver}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div className="fixed top-0 right-0 h-screen w-full bg-white opacity-75 z-50" />
              <div
                className={twMerge(
                  'fixed top-1/2 left-1/2 bg-blue aspect-square rounded-full z-50',
                  'h-60 md:h-80 lg:h-96 2xl:h-[500px]',
                  'text-white font-bold text-center',
                  'text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl -translate-x-1/2 -translate-y-1/2',
                )}
                {...getTestProps(testId, 'add-media')}
              >
                <div className="absolute h-full w-full animate-slowPing rounded-full bg-blue opacity-20" />
                <div
                  className={
                    'h-full w-full p-5 md:p-8 lg:py-10 2xl:py-20 grid grid-rows-3 justify-center items-center'
                  }
                >
                  <UnionIcon className="animate-slowSpin justify-self-center h-2 md:h-4 lg:h-6 opacity-80" />
                  <p>{t('Media.DropFiles')}</p>
                  <p className="px-5 2xl:px-20 self-end opacity-80 text-base md:text-2xl 2xl:text-3xl">
                    {t('Media.AddToLibrary')}
                  </p>
                </div>
              </div>
            </Transition>
          }
        >
          {children ? children : <Outlet />}
        </Structure>
      </SidebarContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default Layout;

Layout.propTypes = {
  /*
   ** Checking if the sidebar is open
   */
  isSidebarOpen: PropTypes.bool,
  /*
   ** Additional classes for layout
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Layout.defaultProps = {
  additionalClasses: '',
  isSidebarOpen: true,
  testId: '',
};
