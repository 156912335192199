import { useContext, useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';

// :: Component
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import SpaceDetails from './SpaceDetails/SpaceDetails';
import Button from '../../components/Button/Button';
import Panel from '../../components/Panel/Panel';
import Tooltip from '../../components/Tooltip/Tooltip';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import { useSpacesList, useUser } from '../../hooks/api';
import useToken from '../../hooks/useToken';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { useModals } from '../../contexts/ModalContext';

// :: Images
import { ArrowUpRightIcon, CrownIcon, RefreshIcon } from '../../images/shapes';

// :: Lib helpers
import { getTestProps, sendEventAndClear } from '../../lib/helpers';
import { manageSubscription } from '../../lib/flotiq-client/api-helpers';

const SPACE_PARAMS = {
  order_by: 'createdAt',
  order_direction: 'desc',
};

const SpacesManage = ({ testId }) => {
  const { updateAppContext } = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userStorage, baseUserEventData, isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modal = useModals();
  const token = useToken();

  const { entity: user } = useUser(userStorage.data.id);

  const {
    data: spacesData,
    isLoading,
    reload: reloadRelations,
  } = useSpacesList(SPACE_PARAMS);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'spaces-and-usage',
      topBar: {
        heading: t('Spaces.SpacesAndUsage'),
      },
      breadcrumbs: [],
      id: 'spacesAndUsage',
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  const handleUpgrade = useCallback(
    (e, id) => {
      e.stopPropagation();
      navigate(`/space/upgrade/${id}`);
    },
    [navigate],
  );

  const handleModalPaymentResult = useCallback(async () => {
    // Url parameter on success
    const paymentSuccessToken = searchParams.has('success') && 'success';
    // Url parameter on cancel
    const paymentCancelToken = searchParams.has('cancel') && 'cancel';
    // Url parameter type. "plan_parameter" || "plan"
    const paymentTypeToken = searchParams.get('type') || null;
    let modalConfig;

    switch (
      `${paymentSuccessToken || paymentCancelToken}_${paymentTypeToken}`
    ) {
      case 'cancel_plan_not_changed':
        // http://localhost:3001/organization?cancel=test&type=plan_not_changed
        modalConfig = {
          id: 'payment-modal-cancel-plan_not_changed',
          contentTransKey: 'AccountSettings.PurchaseModal.PlanNotChanged',
          title: t('ContentForm.Information'),
        };
        break;

      case 'cancel_plan_period_end':
        // http://localhost:3001/organization?cancel=test&type=plan_period_end
        modalConfig = {
          id: 'payment-modal-cancel-plan_period_end',
          contentTransKey: 'AccountSettings.PurchaseModal.CancelAtPeriodEnd',
          title: t('AccountSettings.PurchaseModal.CancelAtPeriodEndTitle'),
        };
        break;

      case 'success_plan_sub_update':
        // http://localhost:3001/organization?success=1&type=plan_sub_update
        modalConfig = {
          id: 'payment-modal-success-plan_sub_update',
          contentTransKey: 'AccountSettings.PurchaseModal.SuccessPlanUpdated',
          title: t('AccountSettings.PurchaseModal.SuccessTitle'),
          titleIcon: <CrownIcon className="h-7 mr-3" />,
        };

        sendEventAndClear(
          {
            event: 'purchase',
            type: 'plan',
            plan_id: searchParams.get('planId') || '',
            plan_name: searchParams.get('planName') || '',
            space_id: searchParams.get('spaceId') || 'inaccessible',
          },
          baseUserEventData,
        );

        break;

      case 'cancel_plan':
      case 'cancel_plan_parameter':
        // http://localhost:3001/organization?cancel=test&type=plan
        // http://localhost:3001/organization?cancel=test&type=plan_parameter
        modalConfig = {
          id: 'payment-modal-cancel-plan',
          contentTransKey: 'AccountSettings.PurchaseModal.CancelPlan',
          title: t('AccountSettings.PurchaseModal.CancelPlanTitle'),
        };
        break;

      case 'success_plan':
        // http://localhost:3001/organization?success=test&type=plan
        modalConfig = {
          id: 'payment-modal-success-plan',
          title: t('AccountSettings.PurchaseModal.SuccessTitle'),
          contentTransKey: 'AccountSettings.PurchaseModal.SuccessPlanCreated',
          titleIcon: <CrownIcon className="h-6 mr-3" />,
        };

        if (searchParams.get('action') === 'add')
          TagManager.dataLayer({
            dataLayer: {
              event: 'space_add',
              plan_id: searchParams.get('planId') || '',
              plan_name: searchParams.get('planName') || '',
              space_id: 'inaccessible',
            },
          });

        sendEventAndClear(
          {
            event: 'purchase',
            type: 'plan',
            plan_id: searchParams.get('planId') || '',
            plan_name: searchParams.get('planName') || '',
            space_id: searchParams.get('spaceId') || 'inaccessible',
          },
          baseUserEventData,
        );

        break;

      case 'success_plan_parameter':
      case 'success_null':
        // http://localhost:3001/organization?success=test&type=plan_parameter
        modalConfig = {
          id: 'payment-modal-success',
          title: t('AccountSettings.PurchaseModal.SuccessTitle'),
          contentTransKey: 'AccountSettings.PurchaseModal.SuccessParamCreated',
          titleIcon: <CrownIcon className="h-7 mr-3" />,
        };

        sendEventAndClear(
          {
            event: 'limit_increase',
            type: searchParams.get('propertyName') || 'plan_parameter',
            space_id: searchParams.get('spaceId') || 'inaccessible',
            plan_id: searchParams.get('planId') || '',
            plan_name: searchParams.get('planName') || '',
          },
          baseUserEventData,
        );

        break;
      default:
        break;
    }

    // Show modal after proper configuration
    if (modalConfig) {
      await modal({
        id: modalConfig.id,
        buttons: [],
        size: 'md',
        content: (
          <>
            <Trans
              i18nKey={modalConfig.contentTransKey}
              components={{
                strong: <strong className={'my-4 text-lg'} />,
                p: <p className={'my-4 text-lg'} />,
              }}
            />
            <Trans
              i18nKey={'AccountSettings.PurchaseModal.QuestionsMessage'}
              components={{
                strong: <strong className={'my-4 text-lg'} />,
                a: (
                  <Link
                    to="mailto:hello@flotiq.com"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </>
        ),
        contentAdditionalClasses: '!pb-10',
        title: (
          <div className="flex justify-center items-center !py-2 !pt-10">
            {modalConfig.titleIcon}
            <Heading level={3} additionalClasses={'!p-0 dark:text-white'}>
              {modalConfig.title}
            </Heading>
          </div>
        ),
      });

      // Remove from url result parameter
      searchParams.delete('success');
      searchParams.delete('cancel');
      searchParams.delete('type');

      // Set updated search parameter
      setSearchParams(searchParams);
    }
  }, [searchParams, t, baseUserEventData, modal, setSearchParams]);

  useEffect(() => {
    handleModalPaymentResult();
  }, [handleModalPaymentResult]);

  const manageSubscriptionClick = useCallback(
    () => manageSubscription(token, userStorage?.data?.id, t),
    [t, token, userStorage?.data?.id],
  );

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-1 xl:grid-cols-4 h-full relative mt-7">
        <div
          className={twMerge(
            'relative bg-white dark:bg-slate-950',
            'rounded-lg mx-4 xl:ml-7 xl:mr-3.5',
            'mb-7 lg:col-span-2 xl:col-span-3',
          )}
        >
          <div
            className={'space-y-3 md:space-y-3 p-4 md:py-4 md:px-6 relative'}
          >
            <Button
              buttonSize="sm"
              buttonColor={'borderless'}
              iconImage={
                <Tooltip
                  tooltip={t('Spaces.RefreshSpacesList')}
                  tooltipPlacement={'topCenter'}
                  phoneTooltipPlacement={'leftCenter'}
                  {...getTestProps(testId, `refresh-tooltip`, 'testId')}
                >
                  <RefreshIcon className="text-blue hover:opacity-80 w-4" />
                </Tooltip>
              }
              additionalClasses={twMerge(
                'absolute right-6 top-8',
                isLoading && 'hidden',
              )}
              onClick={() => reloadRelations()}
              {...getTestProps(testId, 'refresh-space-list', 'testId')}
            />

            <Button
              buttonSize="sm"
              onClick={() => navigate('/space/add')}
              additionalClasses="mr-4"
              id={'add-space'}
              {...getTestProps(testId, 'add-space', 'testId')}
            >
              {t('Spaces.AddSpace')}
            </Button>

            <div className="flex flex-col mt-8 md:min-h-[75vh]">
              {isLoading ? (
                <Loader
                  size="small"
                  type="spinner-grid"
                  additionalClasses="m-auto"
                  {...getTestProps(testId, `loader`, 'testId')}
                />
              ) : (
                spacesData?.map((space) => (
                  <SpaceDetails
                    key={space.id}
                    name={space.name}
                    id={space.id}
                    slug={space.slug}
                    planLimits={space.planLimits}
                    paymentDueDate={space.paymentDueDate}
                    paymentStatus={space.paymentStatus}
                    handleUpgrade={handleUpgrade}
                    isAdmin={isAdmin}
                    {...getTestProps(
                      testId,
                      `space-details-${space.id}`,
                      'testId',
                    )}
                  />
                ))
              )}
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div
          className={twMerge(
            'px-4 xl:pl-3.5 xl:pr-7 pb-7',
            'border-t md:border-t-0 md:border-l dark:border-slate-800',
            'flex flex-col gap-5 w-full',
          )}
        >
          {user?.paymentClientId && (
            <Panel
              isCollapsable={false}
              open={true}
              title={t('AccountSettings.ManageSubscriptionTitle')}
            >
              <span className="mr-1">
                {t('AccountSettings.ManageSubscriptionText')}
              </span>
              <Button
                buttonSize={'sm'}
                as={'button'}
                buttonColor={'borderless'}
                noPaddings
                iconPosition="end"
                iconImage={<ArrowUpRightIcon className="w-2 h-2" />}
                additionalClasses="inline-flex relative whitespace-nowrap text-black underline cursor-pointer"
                onClick={manageSubscriptionClick}
                {...getTestProps(testId, `manage-plan`, 'testId')}
              >
                {t('AccountSettings.ManageSubscriptionLinkText')}
              </Button>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpacesManage;

SpacesManage.propTypes = {
  /**
   * Spaces Manage test id
   */
  testId: PropTypes.string,
};

SpacesManage.defaultProps = {
  testId: '',
};
