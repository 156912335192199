import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

// :: Context
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Api
import { postUserRole } from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';

// :: Hooks
import { useContentTypes, useUserRole } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useFirstLoading from '../../hooks/useFirstLoading';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Images
import { HouseIcon, WarningIcon } from '../../images/shapes';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import UserRoleForm from '../../form/UserRoleForm/UserRoleForm';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import useSelectedSpace from '../../hooks/useSelectedSpace';

const AddUserRole = ({ duplicate, testId }) => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const navigate = useNavigate();
  const navigateOnSave = useRef();
  const { updateAppContext } = useContext(AppContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { id } = useParams();
  const { isAdmin, planLimits } = useContext(UserContext);
  const { setDirty } = useContext(DirtyHandlerContext);

  const { navigateGrid, gridLink } = useGridNavigate(
    'user-roles',
    buildUrlWithSpace('user-roles'),
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const disabledActions =
    isSaving || isDeleting || !planLimits?.user_roles_enabled;

  const {
    entity: role,
    isLoading: roleIsLoading,
    updateEntity: putRole,
    deleteEntity: deleteRole,
    errors,
    status,
  } = useUserRole(id);

  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  const firstLoading = useFirstLoading(
    (!roleIsLoading || !id) && !isLoadingCT,
    id,
  );

  useApiErrorsToast(errors);
  useApiErrorsToast(ctdErrors);

  const handleDeleteRole = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteRole();
      checkResponseStatus(body, status);
      toast.success(t('UserRoles.Deleted'));

      setDirty(false);
      navigateGrid();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('UserRoles.DeletingError'),
        );
      }
    }
  }, [modal, deleteRole, t, setDirty, navigateGrid]);

  const deleteRoleModal = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('UserRoles.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteRole(),
    );
    setIsDeleting(false);
  }, [handleDeleteRole, modal, t]);

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (id) return 'Edit';
    return 'Add';
  }, [duplicate, id]);

  const actionMenuItems = useMemo(() => {
    const items = [
      {
        key: 'leave',
        label: (
          <div className="whitespace-nowrap">{t('Global.SaveAndLeave')}</div>
        ),
        type: 'submit',
        form: 'role-form',
        onClick: () => {
          navigateOnSave.current = true;
        },
      },
    ];
    if (id && !duplicate)
      items.push({
        key: 'duplicate',
        label: <div className="whitespace-nowrap">{t('Global.Duplicate')}</div>,
        link: buildUrlWithSpace(`user-roles/duplicate/${id}`),
      });
    return items;
  }, [duplicate, buildUrlWithSpace, id, t]);

  const topBarButtons = useMemo(() => {
    const buttons = [
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        link: gridLink,
        color: 'gray',
        disabled: isSaving || isDeleting,
        ...getTestProps(testId, 'cancel', 'testId'),
      },
    ];

    if (isAdmin) {
      if (id)
        buttons.unshift({
          key: 'delete',
          label: t('Global.Delete'),
          onClick: deleteRoleModal,
          color: 'redBordered',
          iconImage: isDeleting ? (
            <Loader size="small" type="spinner-grid" />
          ) : (
            ''
          ),
          disabled: disabledActions,
          ...getTestProps(testId, 'delete', 'testId'),
        });
      buttons.push({
        key: 'save',
        label: t('Global.Save'),
        onClick: () => {
          navigateOnSave.current = false;
        },
        iconImage: isSaving ? <Loader size="small" type="spinner-grid" /> : '',
        disabled: disabledActions,
        menuItems: actionMenuItems,
        type: 'submit',
        form: 'role-form',
        ...getTestProps(testId, 'save', 'testId'),
      });
    }

    return buttons;
  }, [
    t,
    gridLink,
    isSaving,
    isDeleting,
    testId,
    isAdmin,
    id,
    deleteRoleModal,
    disabledActions,
    actionMenuItems,
  ]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'user-roles',
      topBar: {
        heading: t(`UserRoles.${pageTitle}`, { roleName: role?.name }),
        buttons: topBarButtons,
      },
    }));
  }, [pageTitle, role?.name, t, topBarButtons, updateAppContext]);

  const saveNewRole = useCallback(
    async (values) => {
      setIsSaving(true);
      try {
        const { body, status } = await postUserRole(jwt, space, {
          name: values.name,
          description: values.description,
          permissions: values.permissions.map((permission) => ({
            type: permission.type,
            contentTypeDefinition: {
              id: permission.contentTypeDefinition?.id || '',
            },
            canRead: permission.canRead,
            canCreate: permission.canCreate,
            canUpdate: permission.canUpdate,
            canDelete: permission.canDelete,
          })),
        });
        setIsSaving(false);
        checkResponseStatus(body, status);
        toast.success(t('UserRoles.Added', { roleName: body.name }));
        navigate(buildUrlWithSpace(`user-roles/edit/${body.id}`));
        return [body, {}, false];
      } catch (error) {
        setIsSaving(false);

        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [values, {}, true];
        }

        toast.error(error.message ? error.message : t('UserRoles.AddingError'));
        return [values, error.errors, true];
      }
    },
    [jwt, navigate, t, space, buildUrlWithSpace],
  );

  const updateRole = useCallback(
    async (values) => {
      setIsSaving(true);
      try {
        const { status, body } = await putRole(values);
        setIsSaving(false);
        checkResponseStatus(body, status);
        toast.success(t('UserRoles.Updated', { roleName: body.name }));
        return [body, {}, false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [values, {}, true];
        }
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('UserRoles.UpdatingError'),
        );
        setIsSaving(false);
        return [values, error.errors, true];
      }
    },
    [putRole, t],
  );

  const filterCtd = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newCtds = await getCtdsFromQuery(
        jwt,
        space,
        { ...ctdParams, label: query },
        t,
      );
      setIsLoading(false);
      return newCtds
        .filter(
          (ctd) =>
            !ctd.internal ||
            ctd.name === '_media' ||
            ctd.name === '_tag' ||
            ctd.name === '_webhooks' ||
            ctd.name === '_plugin_settings',
        )
        .map((ctd) => ({ label: ctd.label, value: ctd.id }));
    },
    [jwt, ctdParams, t, space],
  );

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          key: 'user-roles',
          label: t('Global.UserRoles'),
          link: gridLink,
          additionalClasses: 'text-slate-400 truncate text-center',
        },
        {
          key: 'form',
          label: t(`UserRoles.${pageTitle}`, {
            roleName: role?.name,
          }),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
        },
      ],
    }));
  }, [pageTitle, role?.name, t, updateAppContext, buildUrlWithSpace, gridLink]);

  const showForm = useMemo(
    () => isAdmin && !firstLoading && (role || !id),
    [isAdmin, firstLoading, role, id],
  );

  const noData = useMemo(() => {
    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }
    if (!isAdmin) {
      toast.error(t('Global.NoAccess'));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('UserRoles.CouldntFetch')}
          />
          {status === 404 || !isAdmin
            ? t('Global.NoAccess')
            : t('UserRoles.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [showForm, firstLoading, isAdmin, testId, t, status]);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    const options = contentTypes
      .filter(
        (ctd) =>
          !ctd.internal ||
          ctd.name === '_media' ||
          ctd.name === '_tag' ||
          ctd.name === '_plugin_settings' ||
          ctd.name === '_webhooks',
      )
      .map((ctd) => {
        return {
          value: ctd.id,
          label: ctd.label,
        };
      });

    return options;
  }, [contentTypes]);

  return (
    <div className="flex items-stretch w-full min-h-[calc(100vh-115px)] md:min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t(`UserRoles.${pageTitle}`, { roleName: role?.name })}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        {showForm ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full relative mt-7">
            <div
              className={
                'lg:col-span-2 xl:col-span-3 bg-white dark:bg-slate-950 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7'
              }
            >
              <UserRoleForm
                key={pageTitle}
                role={role}
                onSave={id && !duplicate ? updateRole : saveNewRole}
                ctdsOptions={contentTypesOptions}
                ctdsFilterCallback={filterCtd}
                disabled={disabledActions}
                navigateOnSave={navigateOnSave}
                testId={testId}
              />
            </div>
            <div
              className={twMerge(
                'pt-7 lg:pt-0 px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t lg:border-t-0 lg:border-l dark:border-slate-800',
                'flex flex-col gap-5 w-full',
              )}
            >
              {!planLimits?.user_roles_enabled && (
                <div
                  className="rounded-lg p-5 w-full bg-white dark:bg-slate-950 dark:text-white"
                  {...getTestProps(testId, 'upgrade-plan-info')}
                >
                  <div
                    className={
                      'w-full flex flex-wrap gap-x-2 gap-y-1 items-center justify-between ' +
                      'mr-2 font-bold text-base dark:text-white mb-2'
                    }
                  >
                    {t('UserRoles.CustomRoles')}
                    {planLimits?.price !== -1 && (
                      <LinkButton
                        buttonSize="xs"
                        link={`/space/upgrade/${space}`}
                      >
                        {t('Global.UpgradePlan')}
                      </LinkButton>
                    )}
                  </div>
                  <p className="text-red font-bold mb-3">
                    {t('UserRoles.Disabled')}
                  </p>
                  {t('UserRoles.CustomRolesUpgrade')}
                </div>
              )}
              <div className="rounded-lg p-5 w-full bg-white dark:bg-slate-950 dark:text-white">
                <p className="font-bold text-red mb-3">{t('Global.Warning')}</p>
                {t('UserRoles.Information')}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center h-full
            bg-white dark:bg-slate-950 rounded-lg m-4 xl:m-7"
          >
            {noData}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUserRole;

AddUserRole.propTypes = {
  /**
   * If user role is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddUserRole.defaultProps = {
  duplicate: false,
  testId: '',
};
