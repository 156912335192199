import { useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import useLocalStorageState from 'use-local-storage-state';
import AppContext from '../../contexts/AppContext';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toastRef = useRef();
  const [searchParams] = useSearchParams();
  const logoutReason = searchParams.get('reason');

  const { updateAppContext } = useContext(AppContext);
  const [, , { removeItem }] = useLocalStorageState('cms.user');
  const [, , { removeItem: removeImpersonation }] =
    useLocalStorageState('cms.impersonate');

  useEffect(() => {
    if (toastRef.current) {
      toast.remove(toastRef.current);
    }
    if (logoutReason === 'auth-error') {
      toastRef.current = toast.error(t('Global.UserNotAuthenticated'));
    } else if (logoutReason === 'new-system-version') {
      toastRef.current = toast.error(t('Global.UserWasLogoutNewSystemVersion'));
    }
  }, [logoutReason, t]);

  const logoutSentRef = useRef();

  const handleRemoveSpaceContext = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      space: undefined,
      spaceSlug: undefined,
    }));
  }, [updateAppContext]);

  useEffect(() => {
    if (!logoutSentRef.current) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'logout',
        },
      });
      logoutSentRef.current = true;
    }

    removeImpersonation();
    removeItem();
    handleRemoveSpaceContext();
    window.dataLayer.push(function () {
      this.reset();
    });

    navigate('/login');

    FlotiqPlugins.unregisterAllPlugins();
  }, [navigate, handleRemoveSpaceContext, removeItem, removeImpersonation]);

  return <></>;
};

export default Logout;
