import { useCallback, useMemo } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useToken from '../useToken';
import { getContentObjects, getContentTypes } from '../../lib/flotiq-client';
import useSelectedSpace from '../useSelectedSpace';
import { getCtdFeaturedImage } from '../../lib/helpers';
import { useQuery } from '@tanstack/react-query';

const DEFAULT_OPTIONS = {};

/**
 * Get content types data from API for content types where provided only content type name
 * and return content types dictionary where the key is content type name and the value is content type data.
 * If content type was provided as an object, the fetch will be skipped.
 *
 * @param {Array<string|object>} contentTypes
 * @param {{ jwt: string, space: string, abortSignal: object, checkStatus: boolean }} fetchParams
 * @returns {Promise<Object.<string, object>>}
 */
const getContentTypesDict = async (
  contentTypes,
  { jwt, space, signal, checkStatus, cancelUnfinishedRequests },
) => {
  const contentTypeStrings = contentTypes.filter(
    (ctd) => typeof ctd === 'string',
  );

  let ctdResult;
  if (contentTypeStrings.length) {
    ctdResult = await getContentTypes(
      jwt,
      space,
      {
        names: contentTypeStrings,
        limit: contentTypeStrings.length,
      },
      cancelUnfinishedRequests ? { signal } : {},
    );

    if (checkStatus) {
      checkResponseStatus(ctdResult.body, ctdResult.status);
    }
  }

  const apiContentTypesDict = (ctdResult?.body?.data || []).reduce(
    (acc, ctd) => {
      acc[ctd.name] = ctd;
      return acc;
    },
    {},
  );

  return contentTypes.reduce((acc, ctd) => {
    const contentTypeData =
      typeof ctd === 'string' ? apiContentTypesDict[ctd] || { name: ctd } : ctd;

    acc[contentTypeData.name] = {
      contentTypeData,
      featuredMediaId:
        contentTypeData?.featuredImage?.[0]?.dataUrl?.match(/[^/]+$/)?.[0],
    };
    return acc;
  }, {});
};

/**
 * Get featured images data based on content types dictionary build step before and return them as a dictionary
 * where the key is content type name and the value is media data from API
 *
 * @param {Object.<string, object>} contentTypesDict
 * @param {{ jwt: string, space: string, abortSignal: object, checkStatus: boolean }} fetchParams
 * @returns {Promise<Object.<string, object>>}
 */
const getImagesDict = async (
  imageIds,
  contentTypesDict,
  { jwt, space, signal, checkStatus, cancelUnfinishedRequests },
) => {
  let mediaReponse;
  if (imageIds.length) {
    mediaReponse = await getContentObjects(
      jwt,
      space,
      {
        contentTypeName: '_media',
        ids: imageIds,
        limit: imageIds.length,
      },
      cancelUnfinishedRequests ? { signal } : {},
    );

    if (checkStatus) {
      checkResponseStatus(mediaReponse.body, mediaReponse.status);
    }
  }
  const imageDict = (mediaReponse?.body?.data || []).reduce((acc, media) => {
    acc[media.id] = media;
    return acc;
  }, {});

  return Object.entries(contentTypesDict).reduce(
    (acc, [contentTypeName, { featuredMediaId }]) => {
      acc[contentTypeName] = imageDict[featuredMediaId];
      return acc;
    },
    {},
  );
};

/**
 * Get featured images dictionary where the key is content type name and the value is featured media URL
 *
 * @param {string|Array<string>} contentTypes
 * @param {object} defaultOptions
 * @returns {{
 *    images: Object.<string, string>,
 *    isLoading: boolean,
 *    errors: array | null,
 *    reload: function,
 *  }|[
 *   data: Object.<string, string>,
 *   isLoading: boolean,
 *   errors: array | null,
 *   reload: function,
 * ]}
 */
export const useFeaturedImages = (contentTypes, options = DEFAULT_OPTIONS) => {
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const {
    checkStatus = true,
    cancelUnfinishedRequests = true,
    pause = false,
    staleTime = 0,
    gcTime = 5 * 60 * 1000, //5 minutes
  } = options || {};

  const filteredTypes = useMemo(
    () => contentTypes?.filter((ctd) => ctd) || [],
    [contentTypes],
  );

  const contentTypeStrings = useMemo(
    () =>
      filteredTypes.map((ctd) => (typeof ctd === 'string' ? ctd : ctd.name)) ||
      [],
    [filteredTypes],
  );

  const {
    isLoading: ctdsLoading,
    data: contentTypesDict,
    error: ctdsErrors,
    refetch: reload,
  } = useQuery({
    queryKey: ['content-types', { names: contentTypeStrings }],
    queryFn: ({ signal }) =>
      getContentTypesDict(filteredTypes, {
        jwt,
        space,
        signal,
        checkStatus,
        cancelUnfinishedRequests,
      }),
    enabled: !!jwt && !pause && !!contentTypeStrings?.length,
    staleTime,
    gcTime,
    retry: 0,
  });

  const imageIds = useMemo(
    () =>
      [
        ...new Set(
          Object.values(contentTypesDict || {}).map(
            ({ featuredMediaId }) => featuredMediaId,
          ),
        ),
      ].filter((id) => id),
    [contentTypesDict],
  );

  const loadingData = useMemo(
    () =>
      filteredTypes.reduce((acc, ctd) => {
        const ctdName = typeof ctd === 'string' ? ctd : ctd.name;
        acc[ctdName] = getCtdFeaturedImage(ctdName);
        return acc;
      }, {}),
    [filteredTypes],
  );

  const getImages = useCallback(
    (imageDict) =>
      Object.keys(contentTypesDict || {}).reduce((acc, contentTypeName) => {
        acc[contentTypeName] = getCtdFeaturedImage(
          contentTypeName,
          imageDict[contentTypeName],
        );
        return acc;
      }, {}),
    [contentTypesDict],
  );

  const {
    isLoading: mediaLoading,
    data: images,
    error: mediaErrors,
    isPlaceholderData,
  } = useQuery({
    queryKey: ['content-objects', { contentTypeName: '_media', ids: imageIds }],
    queryFn: ({ signal }) =>
      getImagesDict(imageIds, contentTypesDict, {
        jwt,
        space,
        signal,
        checkStatus,
        cancelUnfinishedRequests,
      }),
    enabled: !!jwt && !pause && !!imageIds?.length,
    staleTime,
    gcTime,
    retry: 0,
    select: getImages,
  });

  const isLoading = ctdsLoading || mediaLoading || isPlaceholderData;

  const errors = useMemo(() => {
    const err = [];
    if (mediaErrors) err.push(mediaErrors);
    if (ctdsErrors) err.push(ctdsErrors);
    return err.length ? err : null;
  }, [ctdsErrors, mediaErrors]);

  const finalImages = useMemo(
    () => (isLoading ? loadingData : images) || {},
    [images, isLoading, loadingData],
  );

  const result = [finalImages, isLoading, errors, reload];
  Object.assign(result, {
    images: finalImages,
    isLoading,
    errors,
    reload,
  });
  return result;
};
