import { useCallback, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';

// :: Components
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import CtoCustomField from '../../components/CtoCustomField/CtoCustomField';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import CustomFormElement from './CustomFormElement/CustomFormElement';
import VariantModalForm from '../VariantModalForm/VariantModalForm';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import NewMediaContext from '../../contexts/NewMediaContext';

// :: Hooks
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Lib
import { generateYupShapeForCto } from './helpers/generateYupShapeForCto';
import { getDefaultValueForType, getTestProps } from '../../lib/helpers';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { FormAfterSubmitEvent } from '../../lib/flotiq-plugins/plugin-events/FormAfterSubmitEvent';

const getInitialData = (properties, schema, contentObject, hasData) =>
  Object.keys(properties).reduce((initials, key) => {
    const defaultDate = moment(
      schema[key].default,
      moment.ISO_8601,
      true,
    ).isValid()
      ? schema[key].default
      : null;
    const defaultValue =
      properties[key].inputType === 'dateTime'
        ? defaultDate
        : schema[key].default;
    const defaultForType = getDefaultValueForType(schema[key].type);
    const init = hasData ? contentObject[key] : defaultValue;
    initials[key] = init || defaultForType;
    return initials;
  }, {});

const ContentObjectForm = ({
  contentObject,
  contentType,
  isEditing,
  onSubmit,
  disabled,
  navigateOnSave,
  hasInitialData,
  formId,
  isFullSize,
  userPlugins,
  onValidate,
  enableReinitialize,
  disabledBuildInValidation,
  additionalFormClasses,
  testId,
  isPatchable,
  overriddenFields,
  setOverriddenFields,
  editedCount,
}) => {
  const { t } = useTranslation();
  const { contentTypeName } = useParams();
  const { buildUrlWithSpace } = useSelectedSpace();
  const modal = useModals();
  const { updateVariants } = useContext(NewMediaContext);

  const { navigateGrid } = useGridNavigate(
    `objects-${contentTypeName}`,
    buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
  );

  const schema = useMemo(() => {
    if (!contentType?.schemaDefinition?.allOf[1]?.properties) return {};
    return contentType.schemaDefinition.allOf[1].properties;
  }, [contentType]);

  const properties = useMemo(() => {
    if (!contentType?.metaDefinition?.propertiesConfig) return {};
    if (!contentType.metaDefinition?.order)
      return contentType.metaDefinition?.propertiesConfig;
    return contentType.metaDefinition.order.reduce((acc, key) => {
      acc[key] = contentType.metaDefinition?.propertiesConfig[key];
      return acc;
    }, {});
  }, [contentType]);

  const requiredFields = useMemo(() => {
    if (!contentType?.schemaDefinition?.required || isPatchable) return {};
    return contentType.schemaDefinition?.required.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
  }, [contentType.schemaDefinition?.required, isPatchable]);

  const validationObject = useMemo(
    () =>
      generateYupShapeForCto(
        schema,
        t,
        requiredFields,
        properties,
        undefined,
        isPatchable,
      ),
    [schema, t, requiredFields, properties, isPatchable],
  );

  const renderFormField = useCallback(
    (key, props, schema, isRequired) => (
      <CtoCustomField
        key={key}
        name={key}
        properties={props}
        schema={schema}
        isRequired={isRequired}
        disabled={disabled}
        additionalClasses="w-full max-w-3xl"
        isFullSize={isFullSize}
        testId={testId}
      />
    ),
    [disabled, isFullSize, testId],
  );

  const initialValues = useMemo(
    () => getInitialData(properties, schema, contentObject, hasInitialData),
    [properties, schema, contentObject, hasInitialData],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [newObject, errors] = await onSubmit(values);
      formik.setStatus({ ...formik.status, errors });

      const success = !errors || !Object.keys(errors).length;

      if (success) {
        if (navigateOnSave?.current) {
          navigateGrid();
        } else {
          formik.resetForm({
            values: getInitialData(properties, schema, newObject, true),
          });
        }
      }

      FlotiqPlugins.run(
        'flotiq.form::after-submit',
        new FormAfterSubmitEvent({
          success,
          contentObject: newObject,
          errors: !success ? errors : null,
          userPlugins,
        }),
      );
    },
    [onSubmit, userPlugins, navigateOnSave, navigateGrid, properties, schema],
  );

  const onVariantCreate = useCallback(
    async (media) => {
      const newVariant = await modal({
        title: t('MediaEdit.AddVariant'),
        content: (
          <VariantModalForm
            media={media}
            variantsNames={(media.variants || []).map(
              (variant) => variant.name,
            )}
          />
        ),
        size: 'xl',
        dialogAdditionalClasses: 'max-h-screen',
      });

      const newVariants = [...(media.variants || []), newVariant];

      if (newVariant) {
        return await updateVariants(media.id, newVariants);
      }

      return [null, false];
    },
    [modal, t, updateVariants],
  );

  const onVariantDelete = useCallback(
    async (media, idx) => {
      if (!media?.variants?.length) return [];

      const newVariants = media.variants.filter(
        (_, variantIdx) => idx !== variantIdx,
      );

      return await updateVariants(media.id, newVariants, true);
    },
    [updateVariants],
  );

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType,
      isEditing,
      initialData: contentObject,
      onVariantCreate,
      onVariantDelete,
      userPlugins,
      isPatchable,
      overriddenFields,
      setOverriddenFields,
      editedCount,
    }),
    [
      contentObject,
      contentType,
      isEditing,
      onVariantCreate,
      onVariantDelete,
      userPlugins,
      isPatchable,
      overriddenFields,
      setOverriddenFields,
      editedCount,
    ],
  );

  return (
    <Formik
      initialValues={JSON.parse(JSON.stringify(initialValues))}
      onSubmit={handleSubmit}
      validationSchema={
        disabledBuildInValidation ? null : yup.object().shape(validationObject)
      }
      enableReinitialize={enableReinitialize}
      validate={(values) => onValidate?.(values)}
    >
      <ContentObjectFormContext.Provider value={contentObjectFormContextValue}>
        <Form
          id={formId}
          className={twMerge(
            'flex flex-col space-y-3 md:space-y-6 pb-5',
            additionalFormClasses,
          )}
          noValidate
          {...getTestProps(testId, 'formik')}
        >
          <CustomFormElement />
          {Object.keys(properties).map((key) =>
            renderFormField(
              key,
              properties[key],
              schema[key],
              requiredFields[key],
            ),
          )}
          <ValidationToastHandler />
          <DirtyHandler />
        </Form>
      </ContentObjectFormContext.Provider>
    </Formik>
  );
};

export default ContentObjectForm;

ContentObjectForm.propTypes = {
  /**
   * Content type
   */
  contentType: PropTypes.object.isRequired,
  /**
   * On submit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Content object to edit
   */
  contentObject: PropTypes.object,
  /**
   * If form is used for object editing
   */
  isEditing: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Does the object have initial data?
   */
  hasInitialData: PropTypes.bool,
  /**
   * Content object form id
   */
  formId: PropTypes.string,
  /**
   * If form is full size
   */
  isFullSize: PropTypes.bool,
  /**
   * User plugins settings
   */
  userPlugins: PropTypes.array,
  /**
   * On validate callback
   */
  onValidate: PropTypes.func,
  /**
   * Form enable reinitialize
   */
  enableReinitialize: PropTypes.bool,
  /**
   * If build in validation is off
   */
  disabledBuildInValidation: PropTypes.bool,
  /**
   * Additional form classes
   */
  additionalFormClasses: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Should the form have switches for enabling fields
   */
  isPatchable: PropTypes.bool,
  /**
   * List of fields to patch
   */
  overriddenFields: PropTypes.shape({}),
  /**
   * Function to set overridden fields
   */
  setOverriddenFields: PropTypes.func,
  /**
   * Count of edited objects
   */
  editedCount: PropTypes.number,
};

ContentObjectForm.defaultProps = {
  contentObject: {},
  isEditing: false,
  disabled: false,
  hasInitialData: false,
  formId: 'cto-form',
  isFullSize: false,
  enableReinitialize: true,
  disabledBuildInValidation: false,
  additionalFormClasses: '',
  testId: '',
  isPatchable: false,
  overriddenFields: {},
  setOverriddenFields: null,
  editedCount: 1,
};
