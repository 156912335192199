import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Component
import Button from '../../components/Button/Button';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Context
import AppContext from '../../contexts/AppContext';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

// :: Images
import {
  WarningIcon,
  ZoomMaleWorkingImage,
  HouseIcon,
} from '../../images/shapes';

const ContentEmpty = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateAppContext } = useContext(AppContext);
  const { buildUrlWithSpace } = useSelectedSpace();

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'content',
      menuItemOpen: t('Global.Content'),
      topBar: {
        heading: t('Global.Content'),
        buttons: [],
      },
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.Content'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'EmptyContent',
        },
      ],
    }));
  }, [t, buildUrlWithSpace, updateAppContext]);

  useOnce(handlePageUpdate);

  return (
    <div
      className={twMerge(
        'flex items-stretch w-full m-[40px]',
        'min-h-[calc(100vh-210px)] md:min-h-[calc(100vh-150px)]',
      )}
    >
      <div
        className={twMerge(
          'flex flex-col',
          'items-center justify-center',
          'bg-white dark:bg-slate-950 w-full rounded-lg p-6 lg:px-12',
        )}
      >
        <ZoomMaleWorkingImage className="flex h-48" />

        <div className="w-full flex flex-col justify-center items-center">
          <p
            className={twMerge(
              'flex items-center text-indigo-950 dark:text-white my-8 font-bold ',
              'text-2xl lg:text-4xl xl:text-5xl xl:leading-[52px]',
            )}
          >
            <WarningIcon className="text-red h-10 mr-3" />
            {t('Global.ContentNoItemInTheMenu')}
          </p>

          <Button
            onClick={() =>
              navigate(buildUrlWithSpace('content-type-definitions'))
            }
            {...getTestProps(testId, 'back-definition-builder')}
          >
            {`${t('Global.BackTo')} ${t('Global.DefinitionBuilder')}`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContentEmpty;

ContentEmpty.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

ContentEmpty.defaultProps = {
  testId: '',
};
