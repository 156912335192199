import { useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { isUserRoleAdmin } from '../../lib/helpers';
import { useUser } from '../../hooks/api';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';

const ProtectedRoute = ({ children, redirect, testId }) => {
  const [user] = useLocalStorageState('cms.user');
  const [impersonate, , { removeItem: removeImpersonation }] =
    useLocalStorageState('cms.impersonate');

  const [searchParams] = useSearchParams();
  const redirect_uri = searchParams.get('redirect_uri');
  const response_type = searchParams.get('response_type');

  const { entity: userData, isLoading: userLoading } = useUser(user?.data?.id);

  useEffect(() => {
    const isRoleAdmin = isUserRoleAdmin(user);
    if (!isRoleAdmin && impersonate) removeImpersonation();
  }, [impersonate, removeImpersonation, user]);

  let currentLocation = window.location.href;
  const publicUrl = (process.env.PUBLIC_URL || window.location.origin).replace(
    /\/{0,10}$/,
    '',
  );
  if (currentLocation.replace(/\/{0,10}$/, '') === publicUrl) {
    currentLocation = '';
  }

  if (redirect_uri && user?.token) {
    const url = new URL(redirect_uri);

    if (response_type === 'code') {
      url.searchParams.append('api_key', user.data.apiReadOnlyToken);
    }

    if (url.host === window.location.host) {
      return <Navigate to={url.pathname + url.search} />;
    } else {
      url.hostname = 'localhost';
      window.location.replace(url.toString());
    }

    return null;
  }

  if (user) {
    if (user.data?.limits_plan?.free_space_limit === undefined) {
      return <Navigate to={'/logout?reason=new-system-version'} replace />;
    } else if (userLoading) {
      return <LoaderPage testId={testId} />;
    } else if (!userData) {
      return <Navigate to={'/logout?reason=auth-error'} replace />;
    } else if (user.token) {
      return children ? children : <Outlet />;
    }
  } else if (redirect) {
    return redirect;
  }
  return (
    <Navigate
      to={
        !currentLocation ? '/login' : `/login?redirect_uri=${currentLocation}`
      }
      replace
    />
  );
};

export default ProtectedRoute;
