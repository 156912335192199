import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';

// :: Hooks
import { useContentTypes, usePluginsLibrary } from '../../hooks/api';
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import useFirstLoading from '../../hooks/useFirstLoading';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import PluginForm from '../../form/PluginForm/PluginForm';
import LoadedUIPluginsPanel from '../../components/LoadedUIPluginsPanel/LoadedUIPluginsPanel';

// :: Components Inner
import PluginItem from './PluginItem/PluginItem';

// :: Context
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';
import {
  deleteContentObject,
  getPlugins,
  postContentObject,
  putContentObject,
} from '../../lib/flotiq-client';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';

// :: Icons
import { HouseIcon } from '../../images/shapes';

const USER_PLUGINS_PARAMS = {
  order_by: 'internal.createdAt',
  order_direction: 'desc',
};

const CTD_PARAMS = {
  limit: 1000,
  order_by: 'label',
  order_direction: 'asc',
  page: 1,
};

const PluginsLegacy = ({ testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { updateAppContext } = useContext(AppContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const modal = useModals();

  const [newPlugin, setNewPlugin] = useState(null);
  const [userPlugins, setUserPlugins] = useState([]);
  const [userPluginsAreLoading, setUserPluginsAreLoading] = useState(true);

  const {
    data: pluginsLibrary,
    isLoading: pluginsLibraryIsLoading,
    errors: pluginsLibraryErrors,
  } = usePluginsLibrary();

  const finalPluginsLibrary = useMemo(() => {
    if (!FlotiqPlugins.enabled()) return pluginsLibrary;

    return [
      ...pluginsLibrary,
      {
        type: 'ui-extension',
        name: 'UI Extension',
        description:
          'Integration with the Flotiq Frontend. It changes the way that some fields/forms are rendered.',
        settings: [{ key: 'source-url', label: 'Source code URL' }],
        version: '0.1',
      },
    ];
  }, [pluginsLibrary]);

  const getUserPluginsData = useCallback(async () => {
    try {
      const { body, status } = await getPlugins(
        jwt,
        space,
        USER_PLUGINS_PARAMS,
      );
      checkResponseStatus(body, status);
      setUserPlugins(body.data);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      }
      toast.error(error.message);
    }
    setUserPluginsAreLoading(false);
  }, [jwt, t, space]);

  useEffect(() => {
    getUserPluginsData();
  }, [getUserPluginsData]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'legacy-plugins',
      topBar: {
        heading: t('Global.Plugins'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_PLUGINS,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.Plugins'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'plugins',
        },
      ],
    }));
  }, [buildUrlWithSpace, t, updateAppContext]);

  useOnce(handlePageUpdate);

  const {
    data: contentTypes,
    isLoading: ctdsAreLoading,
    errors: ctdErrors,
  } = useContentTypes(CTD_PARAMS);

  const firstLoading = useFirstLoading(
    !userPluginsAreLoading && !pluginsLibraryIsLoading && !ctdsAreLoading,
  );

  const finalCtdOptions = useMemo(() => {
    return contentTypes
      .filter((ctd) => ctd.name === '_media' || !ctd.internal)
      .map((ctd) => ({
        label: ctd.label,
        value: ctd.name,
      }));
  }, [contentTypes]);

  const filterCtds = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newCtds = await getCtdsFromQuery(
        jwt,
        space,
        { ...CTD_PARAMS, label: query },
        t,
      );
      setIsLoading(false);
      return newCtds
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));
    },
    [jwt, t, space],
  );

  const handleDeletePlugin = useCallback(
    async (id, pluginName, pluginType) => {
      try {
        const { body, status } = await deleteContentObject(jwt, space, {
          id,
          contentTypeName: '_plugin',
        });
        checkResponseStatus(body, status);
        toast.success(t('Plugins.Deleted', { pluginName }));

        setUserPlugins((prevPlugins) =>
          prevPlugins.filter((plugin) => plugin.id !== id),
        );

        if (pluginType === 'ui-extension') window.location.reload();

        return true;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return false;
        }
        toast.error(
          error.message || t('Plugins.DeletingError', { pluginName }),
        );
        return false;
      }
    },
    [jwt, t, space],
  );

  const showDeletingModal = useCallback(
    async (id, pluginName, pluginType) => {
      modal.deleting('delete-modal');
      await handleDeletePlugin(id, pluginName, pluginType);
    },
    [handleDeletePlugin, modal],
  );

  const deletePlugin = useCallback(
    async (id, pluginName, pluginType) => {
      await modal.delete(
        t('Plugins.DeleteConfirmation', { pluginName }),
        'delete-modal',
        () => showDeletingModal(id, pluginName, pluginType),
      );
    },
    [showDeletingModal, modal, t],
  );

  const handleSave = useCallback(
    async (values, isNew = false) => {
      const saveFunc = isNew ? postContentObject : putContentObject;
      try {
        const { status, body } = await saveFunc(jwt, space, {
          ...values,
          contentTypeName: '_plugin',
          ...(Object.hasOwn(values, 'contentTypeDefinitions')
            ? {
                contentTypeDefinitions: values.contentTypeDefinitions.join(','),
              }
            : {}),
        });
        checkResponseStatus(body, status);
        toast.success(
          t(`Plugins.${isNew ? 'Added' : 'Updated'}`, {
            pluginName: values.name,
          }),
        );

        if (isNew) {
          setNewPlugin(null);
          setUserPlugins((prevPlugins) => {
            const newPlugins = [...prevPlugins];
            newPlugins.unshift(body);
            return newPlugins;
          });
        }

        if (body.type === 'ui-extension') window.location.reload();

        return [[body, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [[values, {}], true];
        }
        const tranlationKey = isNew ? 'AddingError' : 'UpdatingError';
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t(`Plugins.${tranlationKey}`, {
                pluginName: values.name,
              }),
        );
        return [[values, error.errors], true];
      }
    },
    [jwt, t, space],
  );

  const showReplaceModal = useCallback(
    async (pluginName) => {
      return modal.confirmation(t('Plugins.Replace', { pluginName }));
    },
    [modal, t],
  );

  const handleAddNewPlugin = useCallback(
    async (plugin) => {
      if (plugin.name === newPlugin?.name) return;

      const createNewPlugin = newPlugin
        ? await showReplaceModal(plugin.name)
        : true;
      if (createNewPlugin) setNewPlugin({ ...plugin });
    },
    [newPlugin, showReplaceModal],
  );

  useApiErrorsToast(ctdErrors);
  useApiErrorsToast(pluginsLibraryErrors);

  const userPluginsContent = useMemo(() => {
    const noDataContent = newPlugin ? (
      ''
    ) : (
      <span className="dark:text-white">{t('Plugins.EmptyData')}</span>
    );
    return userPlugins.length
      ? userPlugins.map((plugin, idx) => (
          <PluginForm
            key={plugin.id}
            plugin={JSON.parse(JSON.stringify(plugin))}
            onDelete={deletePlugin}
            onSave={(values) => handleSave(values)}
            ctdsOptions={finalCtdOptions}
            ctdsAreLoading={ctdsAreLoading}
            filterCtds={filterCtds}
            additionalDropdownClasses={
              idx === userPlugins.length - 1 ? '!top-auto bottom-full' : ''
            }
            {...getTestProps(testId, plugin.id, 'testId')}
          />
        ))
      : noDataContent;
  }, [
    ctdsAreLoading,
    deletePlugin,
    filterCtds,
    finalCtdOptions,
    handleSave,
    newPlugin,
    t,
    testId,
    userPlugins,
  ]);

  return (
    <div className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('Global.Plugins')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-7">
          <div className="md:col-span-3 px-4 xl:pl-7 xl:pr-3.5 pb-7 w-full h-full">
            {firstLoading ? (
              <div className="h-full overflow-hidden flex justify-center items-center">
                <Loader
                  type="spinner-grid"
                  size="big"
                  {...getTestProps(testId, 'loader', 'testId')}
                />
              </div>
            ) : (
              <>
                {finalPluginsLibrary.length > 0 && (
                  <>
                    <Heading
                      level={3}
                      additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                    >
                      {t('Plugins.AvailablePlugins')}
                    </Heading>

                    <div className="flex flex-col">
                      {finalPluginsLibrary?.map((plugin, idx) => {
                        const { order, disabled, ...rest } = plugin;
                        const key = plugin.name + idx;
                        return (
                          <PluginItem
                            key={key}
                            plugin={rest}
                            disabled={disabled}
                            order={order >= 0 || order ? order : idx}
                            onClick={handleAddNewPlugin}
                            {...getTestProps(
                              testId,
                              `plugin-item-${plugin.name}`,
                              'testId',
                            )}
                          />
                        );
                      })}
                    </div>
                    <div className="my-4 border-t dark:border-slate-800 h-1 w-full" />
                  </>
                )}
                <Heading
                  level={3}
                  additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                >
                  {t('Plugins.YoursPlugins')}
                </Heading>
                <div className="space-y-4">
                  {newPlugin && (
                    <PluginForm
                      key={newPlugin.name}
                      plugin={newPlugin}
                      onSave={(values) => handleSave(values, true)}
                      onDelete={() => setNewPlugin(null)}
                      ctdsOptions={finalCtdOptions}
                      ctdsAreLoading={ctdsAreLoading}
                      filterCtds={filterCtds}
                      {...getTestProps(testId, 'new-plugin', 'testId')}
                    />
                  )}
                  {userPluginsContent}
                </div>
              </>
            )}
          </div>
          <div
            className="col-span-3 xl:col-auto px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l
            w-full xl:mt-10 dark:border-slate-800"
          >
            <LoadedUIPluginsPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginsLegacy;

PluginsLegacy.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

PluginsLegacy.defaultProps = {
  testId: '',
};
